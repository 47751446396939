var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main stocked-list" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-tabs",
          {
            model: {
              value: _vm.active,
              callback: function($$v) {
                _vm.active = $$v
              },
              expression: "active"
            }
          },
          [
            _c(
              "van-tab",
              { attrs: { name: "0", title: "冻品" } },
              [
                _c("van-search", {
                  attrs: {
                    "left-icon": "",
                    placeholder: "可输入柜号/货物名称进行搜索",
                    "show-action": ""
                  },
                  on: { search: _vm.onSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function() {
                        return [
                          _c("div", { on: { click: _vm.onSearch } }, [
                            _vm._v(" 搜索 ")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.searchForm.cabinetNoOrName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchForm, "cabinetNoOrName", $$v)
                    },
                    expression: "searchForm.cabinetNoOrName"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "van-refresh-list-button" },
                  [
                    _c(
                      "van-pull-refresh",
                      {
                        on: { refresh: _vm.onRefresh },
                        model: {
                          value: _vm.refreshing,
                          callback: function($$v) {
                            _vm.refreshing = $$v
                          },
                          expression: "refreshing"
                        }
                      },
                      [
                        _c(
                          "van-list",
                          {
                            attrs: {
                              finished: _vm.finished,
                              "finished-text": _vm.finishedText
                            },
                            on: { load: _vm.onLoad },
                            model: {
                              value: _vm.loading,
                              callback: function($$v) {
                                _vm.loading = $$v
                              },
                              expression: "loading"
                            }
                          },
                          _vm._l(_vm.tableList, function(item) {
                            return _c("van-cell-group", { key: item.id }, [
                              _c(
                                "div",
                                { staticClass: "van-common-left" },
                                [
                                  _c(
                                    "van-radio-group",
                                    {
                                      attrs: { direction: "horizontal" },
                                      model: {
                                        value: _vm.stockContainerId,
                                        callback: function($$v) {
                                          _vm.stockContainerId = $$v
                                        },
                                        expression: "stockContainerId"
                                      }
                                    },
                                    [
                                      _c("van-radio", {
                                        attrs: { name: item.stockContainerId }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "van-common-right",
                                  on: {
                                    click: function($event) {
                                      return _vm.toDetail(item)
                                    }
                                  }
                                },
                                [
                                  _c("van-cell", {
                                    attrs: {
                                      title: "柜号：",
                                      value: _vm.utils.isEffectiveCommon(
                                        item.cabinetNo
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "货物名称：",
                                      value: _vm.utils.isEffectiveCommon(
                                        item.cargoName
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "可提件数：",
                                      value: _vm.utils.isEffectiveCommon(
                                        item.extractableNumber
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "可提重量(KG)：",
                                      value: _vm.utils.isEffectiveCommon(
                                        item.extractableWeight
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "仓库：",
                                      value: _vm.utils.isEffectiveCommon(
                                        item.warehouseName
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "入库时间：",
                                      value: _vm.utils.dataString(
                                        item.actualInDate
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "button-fixed" },
                  [
                    _c(
                      "van-button",
                      {
                        staticClass: "button-fixed-margin",
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.apply("whole")
                          }
                        }
                      },
                      [_vm._v(" 申请整柜提货 ")]
                    ),
                    _c(
                      "van-button",
                      {
                        staticClass: "button-fixed-margin",
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.apply("open")
                          }
                        }
                      },
                      [_vm._v(" 申请拆柜提货 ")]
                    ),
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [_vm._v(" 返回 ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "van-tab",
              { attrs: { name: "1", title: "其他农产品" } },
              [
                _c("van-search", {
                  attrs: {
                    shape: "round",
                    "left-icon": "",
                    "show-action": "",
                    placeholder: "请输入产品名称"
                  },
                  on: { search: _vm.onOtherSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function() {
                        return [
                          _c("div", { on: { click: _vm.onOtherSearch } }, [
                            _vm._v(" 搜索 ")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.searchOtherForm.searchValue,
                    callback: function($$v) {
                      _vm.$set(_vm.searchOtherForm, "searchValue", $$v)
                    },
                    expression: "searchOtherForm.searchValue"
                  }
                }),
                _c(
                  "div",
                  [
                    _c(
                      "van-list",
                      {
                        attrs: {
                          finished: _vm.finished,
                          "finished-text": _vm.finishedText
                        },
                        on: { load: _vm.onLoad },
                        model: {
                          value: _vm.loading,
                          callback: function($$v) {
                            _vm.loading = $$v
                          },
                          expression: "loading"
                        }
                      },
                      _vm._l(_vm.list, function(item) {
                        return _c(
                          "van-cell-group",
                          { key: item.id },
                          [
                            _c(
                              "van-pull-refresh",
                              {
                                on: { refresh: _vm.onOtherRefresh },
                                model: {
                                  value: _vm.refreshing,
                                  callback: function($$v) {
                                    _vm.refreshing = $$v
                                  },
                                  expression: "refreshing"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("van-cell", {
                                      attrs: {
                                        title: "申请支付编号:",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.paymentNo
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "提单号:",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.goodsList
                                            ? _vm.utils.isEffectiveCommon(
                                                item.goodsList[0].cabinetNo
                                              )
                                            : "-"
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "货物名称:",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.goodsName
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "库存重量(KG):",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.stockWeight
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "可提重量(KG):",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.extractableWeight
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "账面重量(KG):",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.bookWeight
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "仓库：",
                                        value: _vm.utils.isEffectiveCommon(
                                          item.warehouseName
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "入库时间：",
                                        value: _vm.utils.dataString(
                                          item.finishInTime
                                        )
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "div-button" },
                                      [
                                        _c(
                                          "van-button",
                                          {
                                            staticClass: "apply-button",
                                            attrs: {
                                              plain: "",
                                              round: "",
                                              size: "small",
                                              type: "info",
                                              "native-type": "button"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.applyDelivery(item)
                                              }
                                            }
                                          },
                                          [_vm._v(" 申请提货 ")]
                                        ),
                                        _c(
                                          "van-button",
                                          {
                                            staticClass: "detail-button",
                                            attrs: {
                                              plain: "",
                                              round: "",
                                              size: "small",
                                              type: "info",
                                              "native-type": "button"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.detail(item)
                                              }
                                            }
                                          },
                                          [_vm._v(" 查看详情 ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "button-fixed" },
                  [
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [_vm._v(" 返回 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }