<template>
  <div class="van-main stocked-list">
    <div class="van-common-detail">
      <van-tabs v-model="active">
        <van-tab name="0" title="冻品">
          <van-search v-model="searchForm.cabinetNoOrName" left-icon="" placeholder="可输入柜号/货物名称进行搜索" show-action @search="onSearch">
            <template #action>
              <div @click="onSearch">
                搜索
              </div>
            </template>
          </van-search>
          <div class="van-refresh-list-button">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
              >
                <van-cell-group v-for="item in tableList" :key="item.id">
                  <div class="van-common-left">
                    <van-radio-group v-model="stockContainerId" direction="horizontal">
                      <van-radio :name="item.stockContainerId" />
                    </van-radio-group>
                  </div>
                  <div class="van-common-right" @click="toDetail(item)">
                    <van-cell title="柜号：" :value="utils.isEffectiveCommon(item.cabinetNo)" />
                    <van-cell title="货物名称：" :value="utils.isEffectiveCommon(item.cargoName)" />
                    <van-cell title="可提件数：" :value="utils.isEffectiveCommon(item.extractableNumber)" />
                    <van-cell title="可提重量(KG)：" :value="utils.isEffectiveCommon(item.extractableWeight)" />
                    <van-cell title="仓库：" :value="utils.isEffectiveCommon(item.warehouseName)" />
                    <van-cell title="入库时间：" :value="utils.dataString(item.actualInDate)" />
                  </div>
                </van-cell-group>
              </van-list>
            </van-pull-refresh>
          </div>
          <div class="button-fixed">
            <van-button type="info" class="button-fixed-margin" block @click="apply('whole')">
              申请整柜提货
            </van-button>
            <van-button type="info" class="button-fixed-margin" block @click="apply('open')">
              申请拆柜提货
            </van-button>
            <van-button type="info" block @click="$router.back()">
              返回
            </van-button>
          </div>
        </van-tab>
        <van-tab name="1" title="其他农产品">
          <van-search
            v-model="searchOtherForm.searchValue"
            shape="round"
            left-icon=""
            show-action
            placeholder="请输入产品名称"
            @search="onOtherSearch"
          >
            <template #action>
              <div @click="onOtherSearch">
                搜索
              </div>
            </template>
          </van-search>
          <div>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <van-cell-group v-for="item in list" :key="item.id">
                <van-pull-refresh v-model="refreshing" @refresh="onOtherRefresh">
                  <div>
                    <van-cell title="申请支付编号:" :value="utils.isEffectiveCommon(item.paymentNo)" />
                    <van-cell title="提单号:" :value="utils.isEffectiveCommon(item.goodsList ? utils.isEffectiveCommon(item.goodsList[0].cabinetNo) : '-')" />
                    <van-cell title="货物名称:" :value="utils.isEffectiveCommon(item.goodsName)" />
                    <van-cell title="库存重量(KG):" :value="utils.isEffectiveCommon(item.stockWeight)" />
                    <van-cell title="可提重量(KG):" :value="utils.isEffectiveCommon(item.extractableWeight)" />
                    <van-cell title="账面重量(KG):" :value="utils.isEffectiveCommon(item.bookWeight)" />
                    <van-cell title="仓库：" :value="utils.isEffectiveCommon(item.warehouseName)" />
                    <van-cell title="入库时间：" :value="utils.dataString(item.finishInTime)" />
                    <div class="div-button">
                      <van-button plain round size="small" type="info" native-type="button" class="apply-button" @click="applyDelivery(item)">
                        申请提货
                      </van-button>
                      <van-button plain round size="small" type="info" native-type="button" class="detail-button" @click="detail(item)">
                        查看详情
                      </van-button>
                    </div>
                  </div>
                </van-pull-refresh>
              </van-cell-group>
            </van-list>
          </div>
          <div class="button-fixed">
            <van-button type="info" block @click="$router.back()">
              返回
            </van-button>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, List, PullRefresh, Cell, CellGroup, Search, Button, RadioGroup, Radio, Toast } from 'vant'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data () {
    return {
      searchForm: {
        cabinetNoOrName: ''
      },
      searchOtherForm: {
        searchValue: ''
      },
      stockContainerId: '',
      finishedText: '没有更多了',
      active: '0',
      page: 1,
      tableList: [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.page = 1
      this.tableList = []
      this.onLoad()
    },
    onLoad () {
      this.searchForm.pageNum = this.page
      this.api.delivery.packListPage(this.searchForm).then(result => {
        const resData = this._.cloneDeep(result.data.value)
        this.page = resData.pageNum + 1
        if (this.refreshing) {
          this.tableList = []
          this.refreshing = false
        }
        if (resData.pages === 0 && this._.isEmpty(resData.list)) {
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          this.loading = false
          this.tableList = this._.concat(this.tableList, resData.list)
          if (resData.pageNum === resData.pages) {
            this.finished = true
            this.finishedText = '没有更多了'
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      })
      this.onOtherSearch()
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.page = 1
      this.onLoad()
    },
    apply (type) {
      if (!this.stockContainerId) {
        Toast.fail('请选择货物')
        return false
      }
      this.$router.push({
        path: `/delivery/apply/${type}`,
        query: {
          stockContainerId: this.stockContainerId
        }
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/delivery/apply/detail',
        query: {
          id: row.id,
          stockContainerId: row.stockContainerId
        }
      })
    },
    onOtherSearch () {
      this.searchOtherForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.storageManagementByWeight.out.stockedPage(this.searchOtherForm).then(result => {
        this.list = result.data.value
        if (this.list.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.list.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.list.pageNum < this.list.pages) {
            this.searchOtherForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onOtherRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchOtherForm.pageNum = 1
      this.getList()
    },
    onOtherLoad () {
      this.getList()
    },
    applyDelivery (row) {
      this.$router.push({ name: 'applyDelivery', query: { batchId: row.batchId } })
    },
    detail (row) {
      this.$router.push({ name: 'stockDetailByWeight', query: { batchId: row.batchId } })
    }
  }
}
</script>

<style lang="less" scoped>

.stocked-list {
  .van-list {
    padding-bottom: 60px;
  }
  .stocked-item {
    font-size: 14px;
    margin-top: 10px;
    position: relative;
    p {
      margin-left: 0px;
      >span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
      }
      >span:nth-child(odd) {
        width: 27%;
        text-align: right;
        overflow: hidden;
      }
      >span:nth-child(even) {
        margin-left: 5px;
        width: 20%;
        overflow: hidden;
      }
    }
    p:nth-child(1) {
      >span:nth-child(even) {
        overflow:visible;
      }
    }
    button {
      margin-right: 10px;
      // position: absolute;
      height: 30px;
      // bottom: 10px;
    }
    .apply-button {
      // right: 90px;
    }
    .detail-button {
      // right: 15px;
    }
    .van-divider {
      margin-top: 40px;
    }
  }
}
.div-button {
  text-align: right;
  height: 35px !important;
  line-height: 1px !important;
  margin: 10px auto !important;
}
.apply-button {
  // right: 90px;
  margin-right: 10px;
}
.detail-button {
  // right: 15px;
  margin-right: 10px;
}
</style>
